import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputLabel from "../../Components/Form/InputLabel";
import InputField from "../../Components/Form/InputField";
import Image from "react-bootstrap/Image";
import { Controller } from "react-hook-form";
import FieldValidationText from "../../Components/Global/fieldValidationText";
import { AuthAPI } from "../../apis/authApi";
import { handleLogin } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { selectLangState } from "../../redux/configurationSlice";
import ButtonWithLoading from "../../Components/buttons/ButtonWithLoading";
import { EMAIL_REGEX, PHONE_REGEX_WITHOUT_SPACES } from "../../utils/constants";
import { generateUniqueKey } from "../../utils/utilityFunctions";
import { MoengageService } from "../../services/moengage";
import useForm from "../../hooks/useForm";

const Login = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { lang } = useSelector(selectLangState);
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });
  const onSuccess = (response) => {
    toast.success(t("Login successful"));
    dispatch(
      handleLogin({
        token: response?.auth?.access_token,
        user: response?.user_profile,
      })
    );
    MoengageService.loginSuccess({
      app_language: lang?.startsWith("ar") ? "ar" : "en",
      "Account Status": response?.user_profile?.status,
      user_id: response?.user_profile?.user_id,
    });
    MoengageService.userProfileDetails({
      "Account Status": response?.user_profile?.status,
      user_country_of_residence: response?.user_profile?.country_of_residence,
      user_preferred_location: response?.user_profile?.preferred_location ?? "",
      user_preferred_currency: response?.user_profile?.preferred_currency ?? "",
      user_app_language: lang?.startsWith("ar") ? "ar" : "en",
      is_email_verified: response?.user_profile?.is_email_verified,
    });
  };
  const onError = (error) => {
    MoengageService.loginFailure({
      failure_reason: error?.message,
    });
    toast.error(error?.message);
  };
  const { mutateAsync: login, status } = useMutation({
    mutationFn: (data) => AuthAPI.login(data),
    onSuccess,
    onError,
  });
  const onSubmit = async (values) => {
    const data = {
      password: values?.password,
    };
    if (EMAIL_REGEX.test(values?.email)) {
      data.email = values?.email?.toLowerCase();
    } else {
      data.mobile_no = values?.email;
    }

    try {
      await login({
        ...data,
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        device_key: generateUniqueKey(),
        device_uid: generateUniqueKey(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setIsActive(!isActive);
  };

  const loginWithUaePass = () => {
    const authEndpoint = process.env.REACT_APP_UAE_PASS_URL;
    const clientId = process.env.REACT_APP_UAE_PASS_KEY;
    const redirectUri = `${window.location?.origin}/login`;
    const scope = process.env.REACT_APP_UAE_PASS_SCOPE;
    const loginUrl = `${authEndpoint}?response_type=code&client_id=${clientId}&scope=${scope}&state=HnlHOJTkTb66Y5H&redirect_uri=${redirectUri}&acr_values=urn:safelayer:tws:policies:authentication:level:low`;
    window.location.assign(loginUrl);
  };

  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const uaeLogin = async (code, state) => {
    try {
      const payload = {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        code: code,
        state: state,
        scope: process.env.REACT_APP_UAE_PASS_SCOPE,
        redirect_uri: `${window.location?.origin}/login`,
        device_key: generateUniqueKey(),
        device_uid: generateUniqueKey(),
      };

      const response = await AuthAPI.uaePassLogin(payload);
      dispatch(
        handleLogin({
          token: response?.auth?.access_token,
          user: response?.user_profile,
        })
      );
      MoengageService.loginSuccess({
        app_language: lang?.startsWith("ar") ? "ar" : "en",
        "Account Status": response?.user_profile?.status,
        user_id: response?.user_profile?.user_id,
      });
      MoengageService.userProfileDetails({
        "Account Status": response?.user_profile?.status,
        user_country_of_residence: response?.user_profile?.country_of_residence,
        user_preferred_location:
          response?.user_profile?.preferred_location ?? "",
        user_preferred_currency:
          response?.user_profile?.preferred_currency ?? "",
        user_app_language: lang?.startsWith("ar") ? "ar" : "en",
        is_email_verified: response?.user_profile?.is_email_verified,
      });
    } catch (error) {
      MoengageService.loginFailure({
        failure_reason: error?.message,
      });
      toast.error(error?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    if (code && state) {
      uaeLogin(code, state);
    }
  }, [code, state]);

  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white font-bukra">
            {t("Sign in")}
            <span className="d-block fw-400 subTitle font-dubai">
              {t("Welcome Back!")}
            </span>
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <InputLabel
                className="font-dubai text-white"
                text={`${t("Email address / Phone no")}.`}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t("Email or phone is required"),
                  validate: (value) => {
                    if (
                      EMAIL_REGEX.test(value) ||
                      PHONE_REGEX_WITHOUT_SPACES.test(value)
                    ) {
                      return true;
                    }
                    return t("Enter valid email or phone number");
                  },
                }}
                render={({ field }) => (
                  <InputField
                    {...field}
                    className="font-dubai text-white alignRight mb-2"
                    placeholder="e.g, adam@abc.com /+971 XX XXX XXXX"
                  />
                )}
              />
              {errors.email && (
                <FieldValidationText errorMessage={errors.email?.message} />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <InputLabel
                className="font-dubai text-white"
                text={`${t("Password")}`}
              />
              <div className="input-group position-relative mb-2">
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: t("Password is required") }}
                  render={({ field }) => (
                    <InputField
                      {...field}
                      className="font-dubai text-white"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("Enter your password here")}
                    />
                  )}
                />

                <span
                  className={`showPassword position-absolute ${
                    isActive ? "active" : ""
                  }`}
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.0013 3.66669C7.7138 3.66669 6.7288 4.67752 6.7288 6.00002C6.7288 7.32252 7.7138 8.33335 9.0013 8.33335C10.2888 8.33335 11.2746 7.32252 11.2746 6.00002C11.2746 4.67752 10.2888 3.66669 9.0013 3.66669ZM9.0013 9.88919C6.88047 9.88919 5.2138 8.17835 5.2138 6.00002C5.2138 3.82252 6.88047 2.11085 9.0013 2.11085C11.1221 2.11085 12.7888 3.82252 12.7888 6.00002C12.7888 8.17835 11.1221 9.88919 9.0013 9.88919ZM9.0013 0.166687C5.2138 0.166687 1.95547 2.57752 0.667969 6.00002C1.95547 9.42252 5.2138 11.8334 9.0013 11.8334C12.7888 11.8334 16.0471 9.42252 17.3346 6.00002C16.0471 2.57752 12.7888 0.166687 9.0013 0.166687Z"
                      fill="#EEEEEE"
                    />
                  </svg>
                </span>
              </div>
              {errors.password && (
                <FieldValidationText errorMessage={errors.password?.message} />
              )}
              <p className="text-end fs-13">
                <Link
                  className="text-white text-decoration-none link-hover"
                  to="/forgot-password"
                >
                  {t("Forgot password")}?
                </Link>
              </p>
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 w-100 font-dubai"
              text={t("Sign in")}
              isLoading={status == "pending"}
            />
            <p className="or-option primary-color position-relative text-center my-3">
              {t("OR")}
            </p>

            <Button
              onClick={(e) => {
                e.preventDefault();
                loginWithUaePass();
              }}
              className="fw-500 w-100 bg-light text-black mb-4 font-dubai"
            >
              <Image
                src="/images/uae-pass-icon.svg"
                alt="icon-uae-pass"
                className="d-inline-block align-middle me-2"
                style={{ width: "24px", height: "auto" }}
              />
              <span className="d-inline-block align-middle">
                {t("Sign in with UAE PASS")}
              </span>
            </Button>
            <p className="text-white fw-700 fs-16">
              {t("Got your invite for Esaad membership")}?{" "}
              <Link
                className="secondary-color text-decoration-none"
                to="/activate-membership"
              >
                {t("Activate")}
              </Link>
            </p>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
