import { apiService } from "./configs/axiosConfig";

export const InqueryAPI = {
  submitQuery: async (data) => {
    try {
      const response = await apiService({
        url: "/contact/us/submit",
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
