import { useCallback, useEffect, useState } from "react";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputLabel from "../Components/Form/InputLabel";
import InputField from "../Components/Form/InputField";
import Button from "react-bootstrap/Button";
import PageBanner from "../Components/Global/PageBanner";

import AppDownload from "../Components/Global/AppDownload";
import { useTranslation } from "react-i18next";
import { InqueryAPI } from "../apis/inqueryApi";
import { useQuery } from "@tanstack/react-query";
import { Controller } from "react-hook-form";
import FieldValidationText from "../Components/Global/fieldValidationText";
import { HomeAPI } from "../apis/homeApi";
import { useSelector } from "react-redux";
import { selectLangState } from "../redux/configurationSlice";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReactSelect from "react-select";
import ButtonWithLoading from "../Components/buttons/ButtonWithLoading";
import { PHONE_REGEX } from "../utils/constants";
import PhoneNumberField from "../Components/Global/phoneInput";
import { selectUserDetails } from "../redux/authSlice";
import { handlePhoneNumber, isEmpty } from "../utils/utilityFunctions";
import DropZone from "../Components/Form/DropZone";
import useForm from "../hooks/useForm";
// import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

// const containerStyle = {
//   width: "400px",
//   height: "400px",
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

const ContactUs = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false);
  const user = useSelector(selectUserDetails);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    type: "",
    email: "",
    name: "",
    mobile_no: "",
    subject: "",
    comment: "",
    address: "",
  };
  const {
    register,
    handleSubmit,
    handleChange,
    control,
    reset,
    trigger,
    setValue,
    setError,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues,
  });
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const { data: inqueriesTypes } = useQuery({
    queryKey: [
      "configuration",
      {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getConfiguration({ ...queryKey[1] }),
  });

  const formatInquiriesTypes = (inqueries) => {
    if (!inqueries || !Array.isArray(inqueries)) {
      return [];
    }
    return inqueries
      .map((inquery) => ({
        id: inquery?.id,
        value: inquery?.type,
        label: inquery.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleSuccessClose = () => {
    reset();
    setShow(false);
    window.scrollTo(0, 0);
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsLoading(true);
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          setIsLoading(false);
          return;
        }
        const result = await executeRecaptcha("register");
        const formData = new FormData();
        formData.append("language", lang?.startsWith("ar") ? "ar" : "en");
        formData.append("platform", "website");
        formData.append("captcha_token", result);
        formData.append("type", values?.type?.value);
        formData.append("email", values?.email);
        formData.append("name", values?.name);
        formData.append("mobile_no", values?.mobile_no);
        formData.append("subject", values?.subject);
        formData.append("comment", values?.comment);
        formData.append("address", values?.address);
        formData.append("company", "ESD");
        if (values?.attachment) {
          formData.append("attachment", values?.attachment);
        }

        await InqueryAPI.submitQuery(formData);
        setShow(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [executeRecaptcha]
  );

  const captchaLoad = async () => {
    try {
      const token = await executeRecaptcha("register");
      setToken(token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      setValue("name", user?.first_name + " " + user?.last_name);
      setValue("email", user?.email);
      setValue("mobile_no", handlePhoneNumber(user?.mobile_no));
      trigger(["name", "email", "mobile_no"]);
    }
  }, [user]);

  useEffect(() => {
    executeRecaptcha && captchaLoad();
  }, [executeRecaptcha]);
  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Contact Us")}`}
      />
      <div className="contentHolder add contactPage">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-4">
              <Col lg={6} xs={12}>
                <div className="fieldColumn">
                  <h2 className="primary-color title-lg fw-700">
                    {t("How can we support you")}
                  </h2>
                  <p>{t("contactUsLabel")}</p>
                  <Form.Group className="mb-3" controlId="name">
                    <InputLabel
                      text={
                        <>
                          {t("Query type")}
                          <span className="text-danger">*</span>
                        </>
                      }
                    />
                    <Controller
                      name="type"
                      control={control}
                      rules={{
                        required: t("Query type is required"),
                        maxLength: {
                          value: 50,
                          message: t(
                            "Query type should be less than 50 characters"
                          ),
                        },
                      }}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          options={formatInquiriesTypes(
                            inqueriesTypes?.inquiry_types
                          )}
                          placeholder={t("Select query type")}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {errors.type && (
                      <FieldValidationText
                        errorMessage={errors.type?.message}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="name">
                    <InputLabel
                      text={
                        <>
                          {t("Name")}
                          <span className="text-danger">*</span>
                        </>
                      }
                    />
                    <InputField
                      className="font-dubai"
                      type="text"
                      placeholder={t("Your full name")}
                      {...register("name", {
                        required: t("Name is required"),
                        maxLength: {
                          value: 100,
                          message: t("Name should be less than 100 characters"),
                        },
                      })}
                      onChange={handleChange}
                    />

                    {errors.name && (
                      <FieldValidationText
                        errorMessage={errors.name?.message}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <InputLabel
                      text={
                        <>
                          {t("Email")}
                          <span className="text-danger">*</span>
                        </>
                      }
                    />
                    <InputField
                      className="font-dubai text-en-start alignRight"
                      type="email"
                      placeholder="xyz@example.com"
                      {...register("email", {
                        required: t("Email is required"),
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t("Invalid email address"),
                        },
                        maxLength: {
                          value: 100,
                          message: t(
                            "Email should be less than 100 characters"
                          ),
                        },
                      })}
                    />
                    {errors.email && (
                      <FieldValidationText
                        errorMessage={errors.email?.message}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="mobile_no">
                    <InputLabel
                      text={
                        <>
                          {t("Mobile")}
                          <span className="text-danger">*</span>
                        </>
                      }
                    />

                    <Controller
                      name="mobile_no"
                      control={control}
                      rules={{
                        required: t("Mobile is required"),
                        // validate: (value) => {
                        //   if (value && !value.match(PHONE_REGEX))
                        //     return t("Please enter a valid number");
                        //   return null;
                        // },
                      }}
                      render={({ field }) => (
                        <PhoneNumberField field={field} trigger={trigger} />
                      )}
                    />

                    {errors.mobile_no && (
                      <FieldValidationText
                        errorMessage={errors.mobile_no?.message}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="subject">
                    <InputLabel
                      text={
                        <>
                          {t("Subject")}
                          <span className="text-danger">*</span>
                        </>
                      }
                    />
                    <InputField
                      className="font-dubai"
                      type="text"
                      placeholder={t("Enter your subject")}
                      {...register("subject", {
                        required: t("Subject is required"),
                        maxLength: {
                          value: 200,
                          message: t(
                            "Subject should be less than 200 characters"
                          ),
                        },
                      })}
                    />
                    {errors.subject && (
                      <FieldValidationText
                        errorMessage={errors.subject?.message}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="comment">
                    <InputLabel
                      text={
                        <>
                          {t("Message")}
                          <span className="text-danger">*</span>
                        </>
                      }
                    />
                    <InputField
                      className="font-dubai"
                      as="textarea"
                      rows={3}
                      placeholder={`${t("Description")}...`}
                      {...register("comment", {
                        required: t("Message is required"),
                        maxLength: {
                          value: 1000,
                          message: t(
                            "Message should be less than 1000 characters"
                          ),
                        },
                      })}
                    />
                    {errors.comment && (
                      <FieldValidationText
                        errorMessage={errors.comment?.message}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="comment">
                    <Controller
                      name="attachment"
                      control={control}
                      render={({ field }) => (
                        <DropZone
                          onFileChange={(value) => {
                            setValue(field?.name, value);
                            trigger(field?.name);
                          }}
                          fileSize={10}
                          onDropRejected={(rejectedFiles) => {
                            if (rejectedFiles.length > 0) {
                              const file = rejectedFiles[0];
                              console.error(file.errors);

                              const error = file.errors[0];

                              if (error.code === "file-invalid-type") {
                                console.error(error.message);
                                toast.error(t("Invalid file type"));
                              } else if (error.code === "file-too-large") {
                                console.error(error.message);
                                toast.error(
                                  t("File size is greater than 10MB")
                                );
                              }
                            }
                          }}
                          acceptedFileType={{
                            "image/png": [".png"],
                            "image/jpeg": [".jpeg"],
                          }}
                        >
                          <div className="d-flex align-items-center form-control gap-2 cursor-pointer">
                            <span className="icon">
                              <svg
                                width="15"
                                height="16"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.333 6.55216C14.2199 6.43901 14.0664 6.37545 13.9063 6.37545C13.7463 6.37545 13.5928 6.43901 13.4796 6.55216L6.90594 13.1542C6.62572 13.4345 6.29305 13.6568 5.92692 13.8085C5.56079 13.9602 5.16836 14.0383 4.77205 14.0383C3.97166 14.0383 3.20404 13.7204 2.63804 13.1545C2.07204 12.5886 1.75403 11.821 1.75398 11.0206C1.75392 10.2203 2.07182 9.45263 2.63774 8.88663L9.00443 2.49398C9.34488 2.15896 9.80392 1.97203 10.2816 1.97392C10.7592 1.9758 11.2168 2.16636 11.5545 2.50407C11.8923 2.84177 12.083 3.29928 12.085 3.77692C12.087 4.25457 11.9002 4.71365 11.5652 5.05418L5.19854 11.4468C5.08371 11.5568 4.93085 11.6182 4.77184 11.6182C4.61283 11.6182 4.45996 11.5568 4.34514 11.4468C4.23199 11.3336 4.16843 11.1802 4.16843 11.0201C4.16843 10.8601 4.23199 10.7066 4.34514 10.5934L10.0135 4.89907C10.1235 4.78524 10.1843 4.63279 10.1829 4.47454C10.1816 4.3163 10.1181 4.16492 10.0062 4.05302C9.89429 3.94112 9.74291 3.87765 9.58467 3.87628C9.42642 3.8749 9.27397 3.93573 9.16014 4.04567L3.49174 9.74003C3.32358 9.90816 3.19018 10.1078 3.09917 10.3275C3.00816 10.5472 2.96132 10.7826 2.96132 11.0204C2.96132 11.2582 3.00816 11.4937 3.09917 11.7134C3.19018 11.9331 3.32358 12.1327 3.49174 12.3008C3.83673 12.63 4.29527 12.8137 4.77214 12.8137C5.249 12.8137 5.70755 12.63 6.05254 12.3008L12.4186 5.90758C12.9727 5.33923 13.2805 4.57549 13.2754 3.78179C13.2703 2.98809 12.9527 2.22835 12.3915 1.66715C11.8302 1.10595 11.0704 0.78848 10.2767 0.783507C9.483 0.778533 8.7193 1.08646 8.15103 1.64058L1.78434 8.03323C0.992004 8.82556 0.546875 9.9002 0.546875 11.0207C0.546875 12.1413 0.992004 13.2159 1.78434 14.0082C2.57667 14.8006 3.65131 15.2457 4.77184 15.2457C5.89237 15.2457 6.967 14.8006 7.75934 14.0082L14.333 7.40797C14.3894 7.35188 14.4342 7.28521 14.4647 7.21177C14.4952 7.13834 14.511 7.05959 14.511 6.98006C14.511 6.90053 14.4952 6.82179 14.4647 6.74835C14.4342 6.67492 14.3894 6.60824 14.333 6.55216Z"
                                  fill="#282828"
                                />
                              </svg>
                            </span>
                            {field?.value?.name || "Attach"}
                          </div>
                        </DropZone>
                      )}
                    />
                    <p
                      className={`${
                        errors?.attachment ? "text-danger" : "text-muted"
                      } text-end mt-1`}
                    >
                      {t("File size: Upto 10 MB, File format: JPEG, PNG")}
                    </p>
                  </Form.Group>
                  <ButtonWithLoading
                    variant="primary"
                    type="submit"
                    className="fw-500 w-100 font-dubai"
                    text={t("Submit")}
                    isLoading={isLoading}
                    disabled={!isValid || !isDirty || isSubmitting || isLoading}
                  />
                </div>
              </Col>
              {/* <Col xl={6} md={6} xs={12}>
                <div className="rightColumn">
                  <div className="mapHolder mb-4">
                    <Image
                      src="/images/mapimage.png"
                      className="w-100 h-100"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </Col> */}
              <Col lg={6} xs={12}>
                <div className="rightColumn">
                  <div className="workHours bg-primary-300">
                    <div className="time-col">
                      <h2 className="fw-700 font-bukra primary-color">
                        {t("Working Hours")}
                      </h2>
                      <div className="timing d-flex align-items-start justify-content-between phone-direction">
                        <div className="time">
                          {/* <span className="d-block fw-700 primary-color label">
                            {t("From")}
                          </span> */}
                          <span className="text-black fw-700 font-bukra title-lg">
                            7:30 AM
                          </span>
                        </div>
                        <div className="time">
                          {/* <span className="d-block fw-700 primary-color label">
                            {t("Till")}
                          </span> */}
                          <span className="text-black fw-700 font-bukra title-lg">
                            2:30 PM
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="infoBlock bg-primary d-flex justify-content-center flex-column ">
                      <h3 className="fw-700 font-bukra text-white mb-3 mb-md-4">
                        {t("After Working Hours")}
                      </h3>
                      <p className="text-white mb-2 mb-md-3">
                        {t("For suggestions & complaints:")}
                      </p>
                      <h3 className="mb-0">
                        <a
                          href="mailto:esaad@dubaipolice.gov.ae"
                          className="secondary-color text-decoration-none"
                        >
                          {t("Email us at {{email}}", {
                            email: "esaad@dubaipolice.gov.ae",
                          })}
                        </a>
                      </h3>
                      <h3 className="mb-0">
                        <span className="secondary-color text-decoration-none">
                          {t("or for further inquiries  Call us at 901")}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <Modal
        show={show}
        onHide={handleSuccessClose}
        className="accountModal successModal"
        centered
      >
        <Button
          className="closeBtn primary-color text-decoration-none fw-700"
          variant="link"
          onClick={handleSuccessClose}
        >
          {t("Close")}
        </Button>
        <Modal.Body className="text-center">
          <div
            style={{ width: "80px", height: "80px" }}
            className="circle-img rounded-circle bg-primary d-inline-flex align-items-center justify-content-center mb-4 mt-md-3"
          >
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: "50px", height: "auto" }}
            >
              <path
                d="M7.74919 17.6626C7.06793 17.6629 6.41457 17.3921 5.93325 16.91L0.443061 11.4219C-0.147687 10.8309 -0.147687 9.87302 0.443061 9.28209C1.034 8.69134 1.99191 8.69134 2.58284 9.28209L7.74919 14.4484L21.4172 0.780463C22.0081 0.189715 22.966 0.189715 23.5569 0.780463C24.1477 1.3714 24.1477 2.32931 23.5569 2.92024L9.56513 16.91C9.08381 17.3921 8.43045 17.6629 7.74919 17.6626Z"
                fill="white"
              />
            </svg>
          </div>
          <h4 className="font-bukra fw-500 text-black">
            {t("Query Submitted Successfully!")}
          </h4>
          <p className="mb-4 px-md-5 mx-auto">
            {t(
              "Thank you for reaching out. We've received your query and will get back to you shortly."
            )}
          </p>
          <Row>
            <Col md={12} xs={12}>
              <Button
                variant="primary"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                onClick={handleSuccessClose}
              >
                {t("Done")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <AppDownload />
    </>
  );
};

export default ContactUs;
