import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Col, Container, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../../apis/homeApi";

const MainHero = () => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const { data: homeData } = useQuery({
    queryKey: [
      "home",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getHomeSections({ ...queryKey[1] }),
  });

  const bannerData = homeData?.sections?.find(
    (section) => section?.section_identifier === "banners_section"
  );
  return (
    <section className="heroMain guest">
      <div className="bgImg">
        <video
          className="main-video"
          autoPlay
          loop
          muted
          playsInline
          preload="none"
        >
          <source
            src="https://entcdn.dubaipolice.gov.ae/videos/bg-homeVideo3.mp4"
            type="video/mp4"
          />
          {t("Your browser does not support the video tag.")}
        </video>
      </div>
      <div className="cover">
        <Swiper
          key={lang?.startsWith("ar") ? "rtl" : "ltr"}
          dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
          navigation={{
            prevEl: ".mainHeroSwiper-button-prev",
            nextEl: ".mainHeroSwiper-button-next",
          }}
          modules={[Navigation, Pagination, EffectFade]}
          pagination={{ clickable: true }}
          className="mainHero-swiper"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
        >
          <SwiperSlide>
            <Container>
              <Row>
                <Col md={7} xs={12}>
                  <div className="textHolder d-flex align-items-center h-100 pb-5">
                    <h1 className="font-bukra fw-700">
                      {bannerData?.title ?? t("home.guest.bannerText")}
                    </h1>
                    {/* <div className="btn-wrap d-md-flex">
                      <Link
                        to="/activate-membership"
                        className="btn btn-primary fw-700 font-dubai d-block w-md-100"
                      >
                        {t("home.guest.Activate Membership")}
                      </Link>
                      <Link
                        className="btn btn-outline fw-700 font-dubai register ms-md-3"
                        to="/register-company"
                      >
                        {t("home.guest.Register as a Company")}
                      </Link>
                    </div> */}
                  </div>
                </Col>
                <Col md={5} xs={12} className="d-none d-lg-block">
                  <div className="imgHolder">
                    <Image
                      src="/images/webImg-new.png"
                      alt="Web Image"
                      className="d-block img-fluid w-100"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row>
                <Col md={7} xs={12}>
                  <div className="textHolder">
                    <h1 className="font-bukra fw-700">
                      {bannerData?.title ?? t("home.guest.text")}
                    </h1>
                    <div className="btn-wrap d-md-flex">
                      <Link
                        to="#"
                        className="btn btn-primary fw-700 font-dubai d-block w-md-100"
                      >
                        {t("home.guest.Activate Membership")}
                      </Link>
                      <Link
                        className="btn btn-outline fw-700 font-dubai register ms-md-3"
                        to="/register-company"
                      >
                        {t("home.guest.Register as a Company")}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={5} xs={12} className="d-none d-lg-block">
                  <div className="imgHolder d-flex d-xl-block align-items-center h-100">
                    <Image
                      src="/images/webImg-new.png"
                      alt="Web Image"
                      className="d-block img-fluid w-100"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default MainHero;
